import { createReducer } from '@reduxjs/toolkit';
import {selectIdAction} from "../actions/newAction";

const initialState = {
    selectedBlockId: '',
};

export default createReducer(initialState, (builder) => {
    builder
        .addCase(selectIdAction, (state, action) => {
            // @ts-ignore
            state.selectedBlockId = action.payload;
        });
});
