import {useState} from "react";
import ARROW from "../../assets/arrow.svg";
import "./index.css";
import Button from "../Button";
import cookies from "js-cookie";
import {storageBase} from "../../utils/storage";
import {HeaderKeyOf} from "../../utils/keyof.type";
import {newsTypes} from "../News";
import FLAG from "../../assets/flag.svg";
import {NavLink} from "react-router-dom";

interface NewsSliderProps {
    data: any;
    isBig: boolean;
    isProject: boolean
}

const NewsSlider: React.FC<NewsSliderProps> = ({data, isBig = false, isProject}) => {
    const [active, setActive] = useState(1);
    const lang = cookies.get("i18next");

    const prevSlide = () => {
        setActive((active - 1 + data.length) % data.length);
    };

    const nextSlide = () => {
        setActive((active + 1) % data.length);
    };
    return (
        <div style={{
            width: isBig ? '30rem' : '',
            height: isBig ? '40rem' : '',
            perspective: isBig ? 1000 : 500
        }} className='card_slider news_slider'>
            {data.map((item: any, index: number) => {
                const dynamicTitle = item[`title_${lang}` as keyof HeaderKeyOf];
                const altText = typeof dynamicTitle === "string" ? dynamicTitle : "";
                return (
                    <div
                        key={index}
                        className='card-container'
                        style={
                            {
                                height: isBig ? '100%' : '',
                                "--offset": (active - index) / 3,
                                "--abs-offset": Math.abs(active - index) / 3,
                                "--direction": Math.sign(active - index),
                                "--opacity": Math.abs(active - index) <= 1 ? 1 : 0,
                                "--active": index === active ? 1 : 0,
                            } as React.CSSProperties
                        }>
                        <div style={{
                            // boxShadow: isBig ? 'none' : '',
                            width: isBig ? 350 : '',
                            height: isBig ? '98%' : isProject ? 233 : '',
                            display: isBig ? 'flex' : '',
                            flexDirection: isBig ? 'column' : 'row',
                            justifyContent: isBig ? 'space-between' : ''
                        }} className={`news`} key={item.id}>
                            <div style={{
                                height: isBig ? '100%' : ''
                            }} className='newsImg'>
                                <img
                                    style={{
                                        objectFit: 'cover'
                                    }}
                                    src={item?.project?.banner_image ? `${storageBase}/${item?.project?.banner_image}` : `${storageBase}/${item.image}`}
                                    alt={altText}
                                    decoding='async'
                                    loading='lazy'
                                />
                            </div>
                            {isProject ? (<div style={{
                                padding: 20,
                                borderRadius: 'inherit',
                                display:'flex',
                                justifyContent:'space-between',
                                alignItems:"flex-start"

                            }}>
                                <NavLink
                                    to={`/${lang}/projects/${item.project.slug}`}
                                    style={{
                                    color: "black",
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    padding:0,
                                    margin:0
                                }}>
                                    {item.project[`project_name_${lang}`]}
                                </NavLink>
                                <div className='author'>
                                    {item.map_count > 0 && (
                                        <span className='flag'>
              <img src={FLAG} alt='Flag' decoding='async' loading='lazy'/>
                                            {item.map_count}
            </span>
                                    )}
                                </div>
                            </div>) : (
                                <div className='newsContent'>
                                    <h1>{dynamicTitle}</h1>
                                    <div
                                        style={{display: isBig ? 'none' : ""}}
                                        dangerouslySetInnerHTML={{
                                            //@ts-ignore
                                            __html: item[`description_${lang}` as keyof newsTypes],
                                        }}
                                    />
                                    <p>{item?.created_at?.split("T")[0] as keyof newsTypes}</p>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
            <button className='nav left' onClick={prevSlide}>
                <img src={ARROW} alt='Arrow' decoding='async' loading='lazy'/>
            </button>
            <button className='nav right' onClick={nextSlide}>
                <img src={ARROW} alt='Arrow' decoding='async' loading='lazy'/>
            </button>
        </div>
    );
};

export default NewsSlider;
